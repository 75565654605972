import { sendDelete, sendGet, sendPost, sendPut } from './axios';
export const getListOrder = (payload: any) => sendGet(`/cms/order`, payload);

export const getDetailOrderShopping = (orderId: number) => sendGet(`/cms/order/detail-shopping/${orderId}`);

export const sendRequestPayment = (orderId: number, payload: any) =>
  sendPost(`/cms/order/request-payment/${orderId}`, payload);

export const updateRequestPayment = (orderId: number, payload: any) =>
  sendPut(`/cms/order/request-payment/${orderId}`, payload);

export const getStaticOrder = () => sendGet(`/cms/order/statis-order`);

export const importWarehouse = (orderId: number, payload: any) =>
  sendPost(`/cms/order/import-warehouse/${orderId}`, payload);

export const statisComment = (orderId: number) => sendGet(`/cms/order/statis-comment/${orderId}`);

export const listComment = (orderId: number, payload: any) => sendGet(`/cms/order/list-comment/${orderId}`, payload);

export const sendComment = (orderId: number, payload: any) => sendPost(`/cms/order/send-comment/${orderId}`, payload);

export const updatePaymentSystemFee = (orderId: number, payload: any) =>
  sendPut(`/cms/order/payment-system-fee/${orderId}`, payload);

export const requestPickup = (orderId: number) => sendPost(`/cms/order/request-pickup/${orderId}`);

export const createTransportOrder = (orderId: number) => sendPost(`/cms/order/create-transport-order/${orderId}`);

export const deliverStock = (orderId: number) => sendPost(`/cms/order/stock-delivering/${orderId}`);

export const confirmPayment = (orderId: number) => sendPut(`/cms/order/confirm-payment/${orderId}`);

export const updateDetailItemShopping = (orderId: number, payload: any) =>
  sendPut(`/cms/order/detail-items/${orderId}`, payload);

export const updateReceiverAddress = (orderId: number, payload: any) =>
  sendPut(`/cms/order/receiver-address/${orderId}`, payload);

export const createOrderImage = (orderId: number, payload: any) =>
  sendPost(`/cms/order/create-image/${orderId}`, payload);

export const removeImageOrder = (id: number) => sendDelete(`/cms/order/remove-image/${id}`);

export const getOrderImage = (orderId: number) => sendGet(`/cms/order/image/${orderId}`);

export const getOrderCategoryService = (orderId: number) => sendGet(`/cms/order/category-service/${orderId}`);

export const getDetailDocument = (orderId: number) => sendGet(`/cms/order/detail-document/${orderId}`);

export const addOrderDocument = (orderId: number, payload: any) =>
  sendPost(`/cms/order/add-document/${orderId}`, payload);

export const updateOrderDocument = (orderId: number, payload: any) =>
  sendPost(`/cms/order/update-document/${orderId}`, payload);

export const removeOrderDocument = (documentId: number) => sendDelete(`/cms/order/remove-document/${documentId}`);

export const createTransportOrders = (orderIds: number[]) =>
  sendPost(`/cms/order/create-transport-order`, { orderIds });

export const requestPickupOrders = (orderIds: number[]) => sendPost(`/cms/order/request-pickup`, { orderIds });

export const getSystemFee = (orderId: number, payload: any) => sendGet(`/cms/order/system-fee/${orderId}`, payload);

export const importWarehouseMulti = (payload: any) =>
  sendPost(`/cms/order/import-warehouse`, payload);

export const getOrderInformation = (orderNumber: string) => sendGet(`/cms/order/information/${orderNumber}`);

export const getStorageFee = (orderId: number, payload: any) => sendGet(`/cms/order/storage-fee/${orderId}`, payload);

export const updateStorageDeadline = (orderId: number, payload: any) =>
  sendPut(`/cms/order/storage-deadline/${orderId}`, payload);

export const requestPaymentAdditional = (orderId: number, payload: any) =>
  sendPost(`/cms/order/request-payment-additional/${orderId}`, payload);

export const cancelRequestPayment = (orderId: number, payload: any) =>
  sendPut(`/cms/order/cancel-request-payment-additional/${orderId}`, payload);

export const updateOrderSize = (orderId: number, payload: any) =>
  sendPut(`/cms/order/update-order-size/${orderId}`, payload);

export const deliverStockMulti = (payload: any) => sendPost(`/cms/order/stock-delivering`, payload);

export const getStaticOrderError = () => sendGet(`/cms/order/statis-order-error`);

export const updateStatusCompleted = (orderId: number) =>
  sendPut(`/cms/order/update-status-completed/${orderId}`);

export const updateStatusFailed = (orderId: number) =>
  sendPut(`/cms/order/update-status-failed/${orderId}`);
