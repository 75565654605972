import React, { Suspense, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import RootWrapper from 'wrappers/RootWrapper';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import configs from 'config';
import * as ordersApi from 'api/orders';
import { OrderStatus, OrderType } from 'common/enum';
import CustomNotification from 'components/CustomNotification';
import { useTranslation } from 'react-i18next';
import { checkPathIsMultipleBarcodeError, checkOrderRequest, handleErrorMessage } from 'helper';
import HelmetHeader from 'components/HelmetHeader';

export const history = createBrowserHistory();
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
    },
  },
});
function App() {
  const { t } = useTranslation();
  useEffect(() => {
    const getOrderInformation = async (orderNumber: string) => {
      const pathName = history.location.pathname;
      try {
        const { data } = await ordersApi.getOrderInformation(orderNumber);
        if (
          checkPathIsMultipleBarcodeError(pathName, data.orderType, data.status) ||
          !checkOrderRequest(data.orderType, data.status)
        ) {
          CustomNotification({
            type: 'error',
            message: t('common.error'),
            description: t('notification.order_changed_status'),
          });
          return;
        }
        if (data.status === OrderStatus.REQUESTED && data.orderType === OrderType.SHIPPING) {
          history.push({
            pathname: '/shipping/import-warehouse-multiple',
            state: { orderId: data.id },
          });
        }
        if (data.status === OrderStatus.CREATE_TRANSPORT_ORDER && data.orderType === OrderType.SHIPPING) {
          history.push({
            pathname: '/shipping/stock-delivery-multiple',
            state: { orderId: data.id },
          });
        }
        if (data.status === OrderStatus.PURCHASING && data.orderType === OrderType.SHOPPING) {
          history.push({
            pathname: '/shopping/import-warehouse-multiple',
            state: { orderId: data.id },
          });
        }
        if (data.status === OrderStatus.CREATE_TRANSPORT_ORDER && data.orderType === OrderType.SHOPPING) {
          history.push({
            pathname: '/shopping/stock-delivery-multiple',
            state: { orderId: data.id },
          });
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    };

    const barcodeHandler = (event: any) => {
      // alert(event.detail.code);
      getOrderInformation(event.detail.code);
    };
    window.addEventListener('barcode', barcodeHandler);
    return () => {
      window.removeEventListener('barcode', barcodeHandler);
    };
  }, [t]);

  return (
    <QueryClientProvider client={client}>
      <Router history={history}>
        <Suspense fallback={null}>
          <HelmetHeader />
          <RootWrapper />
          {configs.APP_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
