import React from 'react';
import { Helmet } from 'react-helmet';

export default function HelmetHeader() {
  return (
    <Helmet>
      <title>Transport System</title>
    </Helmet>
  );
}
