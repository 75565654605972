import React from 'react';
import { CSpinner } from '@coreui/react';

const LoadingSpinner = () => {
  return (
    <div className="absolute-center">
      <CSpinner color="primary" style={{ width: '4rem', height: '4rem' }} />
    </div>
  );
};

export default LoadingSpinner;
