import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import i18next from 'i18next';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { listLanguageProps } from 'interfaces';

export default function LanguageDropdown() {
  const { t } = useTranslation();
  const [imageFlag, setImageFlag] = useState<string>('');
  const { data: listLanguage }: any = useQuery('listLanguages');

  useEffect(() => {
    const currentFlag = listLanguage?.data?.find((language: { code: string }) => language.code === i18next.language)
      ?.flagIcon;
    setImageFlag(currentFlag);
  }, [listLanguage]);

  const getListLanguageRender = () => {
    return (
      <Menu style={{ minWidth: 116 }}>
        {listLanguage?.data?.map((language: listLanguageProps, index: number) => {
          return (
            <Menu.Item
              key={index}
              onClick={() => {
                i18next.changeLanguage(language.code);
                setImageFlag(language.flagIcon);
              }}
            >
              {language.name}
            </Menu.Item>
          );
        })}
        <Menu.Item
          onClick={() => {
            i18next.changeLanguage('cimode');
          }}
        >
          {`Show key`}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div>
      {i18next.language !== 'cimode' && <img src={imageFlag} style={{ maxWidth: '30px' }} alt={'flag'} />}
      &nbsp;
      <Dropdown overlay={getListLanguageRender} trigger={['click']}>
        <Button style={{ minWidth: 116 }}>
          {t('_.language')}
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
}
