import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import SideNav from 'components/SideNav';
import PageHeader from 'components/PageHeader';
import { useQuery } from 'react-query';
import Cookies from 'js-cookie';
import { loadProfile } from 'api/profile';
import MainContent from 'components/MainContent';
import LoadingSpinner from 'components/LoadingSpinner';
import { getListRoles } from 'api/roles';
import { getAllResource } from 'api/resources';
import { getListConfigAdmin } from 'api/configs';

export default function AuthWrapper() {
  const [sidebarShow, setSidebarShow] = useState<any>(true);
  const [hideSideNav, setHideSideNav] = useState(false);
  const location = useLocation();
  const isAuthenticated = !!Cookies.get('token');
  const { data: profile } = useQuery('profile', loadProfile, { enabled: isAuthenticated });
  const { data: listRole } = useQuery('listRoles', getListRoles, { cacheTime: Infinity, enabled: isAuthenticated });
  const { data: resources } = useQuery('resources', getAllResource, { cacheTime: Infinity, enabled: isAuthenticated });
  const { data: listConfigs } = useQuery('listConfigs', () => getListConfigAdmin({}), {
    cacheTime: Infinity,
    enabled: isAuthenticated,
  });

  useEffect(() => {
    setHideSideNav(location.pathname.includes('multiple'));
  }, [location.pathname]);

  if (!isAuthenticated) return <Redirect to="/login" />;
  if (!profile) return <LoadingSpinner />;

  const toggleSidebar = () => {
    setSidebarShow(!sidebarShow);
  };

  const toggleSidebarMobile = () => {
    setSidebarShow(!sidebarShow);
  };

  const onShowChange = (val: any) => {
    setSidebarShow(val);
  };

  if (!listRole || !resources || !listConfigs) return <LoadingSpinner />;

  return (
    <div className="c-app c-default-layout">
      {!hideSideNav && <SideNav sidebarShow={sidebarShow} onShowChange={onShowChange} />}
      <div className="c-wrapper">
        <PageHeader toggleSidebar={toggleSidebar} toggleSidebarMobile={toggleSidebarMobile} />
        <MainContent />
      </div>
    </div>
  );
}
