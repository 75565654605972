import React from 'react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import Cookies from 'js-cookie';
import Avatar from 'assets/images/avatar.png';
import { LogoutOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaUserEdit, FaUserLock } from 'react-icons/fa';

interface Iprops {
  profile: {
    avatar: string;
  };
}

const TheHeaderDropdown = ({ profile }: Iprops) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleLogout = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    history.push('/login');
  };

  return (
    <CDropdown inNav className="c-header-nav-items">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <img src={profile.avatar || Avatar} alt="Avatar" className="c-avatar-img" />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem onClick={() => history.push('/account/profile')}>
          <FaUserEdit className="mfe-2" />
          {t('nav.profile')}
        </CDropdownItem>
        <CDropdownItem onClick={() => history.push('/account/change-password')}>
          <FaUserLock className="mfe-2" />
          {t('nav.change_password')}
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={handleLogout}>
          <LogoutOutlined className="mfe-2" />
          {t('nav.logout')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
