import { sendGet, sendPost, sendPut } from './axios';
export const getListCustomer = (payload: any) => sendGet(`/cms/customer`, payload);
export const getListTrackingNumber = (payload: any) => sendGet(`/cms/customer/tracking-number`, payload);
export const getStatistic = () => sendGet(`/cms/customer/statistic`);
export const getInfoCustomer = (id: number) => sendGet(`/cms/customer/${id}/detail`);
export const verifyCustomer = (id: number) => sendPost(`/cms/customer/verify-account/${id}`);
export const editCustomer = (id: number, payload: any) => sendPut(`/cms/customer/${id}/edit`, payload);
export const getListOrderCustomer = (id: number, payload: any) => sendGet(`/cms/customer/${id}/orders`, payload);
export const getInvoiceSubscriptions = (id: number, payload: any) => sendGet(`/cms/customer/${id}/invoices`, payload);
export const createCustomer = (payload: any) => sendPost(`/cms/customer/create`, payload);
export const updateStatusCustomer = (id: number, payload: any) => sendPut(`/cms/customer/${id}/update-status`, payload);
export const acceptUpgradeCustomer = (id: number) => sendPut(`/cms/customer/${id}/upgrade`);
export const cancelUpgradeCustomer = (id: number) => sendPut(`/cms/customer/${id}/cancel-upgrade`);
