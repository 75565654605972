import { sendPost, sendPut } from 'api/axios';
import { sendGet } from './axios';

// eslint-disable-next-line import/prefer-default-export
export const getListConfigAdmin = (payload: any) => sendGet('/cms/config/admin', payload);

export const getListConfigSystem = (payload: any) => sendGet('/cms/config/system', payload);

export const updateConfig = (configsKey: string, payload: any) => sendPut(`/cms/config/${configsKey}`, payload);

export const getCommonServiceFee = (payload: any) => sendGet('/cms/config/common-service-fee', payload);

export const addCommonServiceFee = (payload: any) => sendPost(`/cms/config/common-service`, payload);

export const updateCommonServiceFee = (serviceId: number, payload: any) => sendPut(`/cms/config/common-service/${serviceId}`, payload);

export const getWarehouseAddress = () => sendGet('/cms/config/warehouse-address');

export const updateWarehouseAddress = (configsKey: string, payload: any) => sendPut(`/cms/config/admin/warehouse-address/${configsKey}`, payload);

export const getListCommissionFee = (payload: any) => sendGet('/cms/commission-fee', payload);

export const addCommissionFee = (payload: any) => sendPost(`/cms/commission-fee`, payload);

export const updateCommissionFee = (serviceId: number, payload: any) => sendPut(`/cms/commission-fee/${serviceId}`, payload);

export const updateStatusCommissionFee = (serviceId: number, payload: any) => sendPut(`/cms/commission-fee/update-status/${serviceId}`, payload);

export const updateStatusCommonService = (serviceId: number, payload: any) => sendPut(`/cms/config/common-service/update-status/${serviceId}`, payload);
