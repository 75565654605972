import { notification } from 'antd';
import { NoticeProps } from 'interfaces';

const CustomNotification = ({ type, message, description }: NoticeProps) => {
  notification.destroy();
  return notification[type]({
    message,
    description,
  });
};

export default CustomNotification;
