export enum Status {
  Active = 1,
  Inactive = 0,
}

export enum permissions {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  CONFIG_MANAGEMENT = 3,
  PERMISSION_MANAGEMENT = 4,
  USER_MANAGEMENT = 5,
  TEMPLATE_MAIL_MANAGEMENT = 6,
  OPTION_SERVICE_MANAGEMENT = 7,
  SHOPPING_MANAGEMENT = 8,
  SHIPPING_MANAGEMENT = 9,
  CUSTOMER_MANAGEMENT = 10,
  COUNTRY_MANAGEMENT = 11,
  REASON_REPORT_MANAGEMENT = 12,
  VENDOR_MANAGEMENT = 13,
  CATEGORY_MANAGEMENT = 14,
  DOCUMENT_MANAGEMENT = 15,
  REPORT_ORDER_CUSTOMER = 16,
  INVOICE_ORDER_CUSTOMER = 17,
  REVENUE = 18,
  WAREHOUSE = 19,
  DASHBOARD = 20,
}

export enum IsSystem {
  Yes = 1,
  No = 0,
}

export enum IsVisible {
  Yes = 1,
  No = 0,
}

export enum OptionalServiceType {
  SHIPPING = 1,
  SHOPPING = 2,
  BOTH = 3,
}
export enum ShoppingType {
  BUY = 1,
  SHIP = 2,
}
export enum OrderPaymentStatus {
  PENDING = 1, // cho client thanh toan hoa don
  CONFIRMED = 2, // client da thanh toan
  ADDITIONAL = 3, // Thanh toán bo sung
}
export enum CustomerAccountType {
  PERSONAL = 1,
  BUSINESS = 2,
}
export enum OrderStatus {
  REQUESTED = 1,
  PAYMENT_PENDING = 2,
  PURCHASING = 3,
  IMPORTED = 4,
  PAYMENT_CONFIRMED = 5,
  CREATE_TRANSPORT_ORDER = 51,
  PICKED_UP = 52, // SHIPPING VENDOR
  DELIVERING = 6,
  ERROR = 61,
  COMPLETED = 7,
  DELIVERY_FAILED = 8,
  CANCELED = 9,
}
export enum OrderTrackingType {
  REQUESTED = 1,
  PROCESSING = 12, // SHIPPING VENDOR
  PICKED_UP = 13, // SHIPPING VENDOR
  PAYMENT_PENDING = 2,
  PURCHASING = 3,
  IMPORTED = 4,
  PAYMENT_CONFIRMED = 5,
  CREATE_TRANSPORT_ORDER = 51, // For Admin
  DELIVERING = 6,
  COMPLETED = 7,
  DELIVERY_FAILED = 8,
  ADDITIONAL_PAYMENT_SENT = 90,
  ADDITIONAL_PAYMENT_CONFIRMED = 91,
}
export enum ConfigKeys {
  RESOURCE_VERSION = 'RESOURCE_VERSION',
  LANGUAGE_VERSION = 'LANGUAGE_VERSION',
  DATE_REQUEST_BUSINESS_PAYMENT = 'DATE_REQUEST_BUSINESS_PAYMENT',
  CURRENCY_RATE = 'CURRENCY_RATE',
  SUBSCRIPTION_DOMESTIC_PRICE = 'SUBSCRIPTION_DOMESTIC_PRICE',
  SUBSCRIPTION_FOREIGN_PRICE = 'SUBSCRIPTION_FOREIGN_PRICE',
  COMMON_SERVICE_FEE_FOREIGN_BUSINESS = 'COMMON_SERVICE_FEE_FOREIGN_BUSINESS',
  COMMON_SERVICE_FEE_FOREIGN_PERSONAL = 'COMMON_SERVICE_FEE_FOREIGN_PERSONAL',
  SHIPPING_FEE_FOREIGN_BUSINESS = 'SHIPPING_FEE_FOREIGN_BUSINESS',
  SHIPPING_FEE_FOREIGN_PERSONAL = 'SHIPPING_FEE_FOREIGN_PERSONAL',
  TRIAL_DURATION = 'TRIAL_DURATION',
  ADDRESS_WAREHOUSE = 'ADDRESS_WAREHOUSE',
  ADDRESS_WAREHOUSE_JP = 'ADDRESS_WAREHOUSE_JP',
}

export enum InvoiceType {
  QUOTATION = 1,
  ADDITIONAL = 2,
  GOODS = 3,
  DELIVERY = 4,
}

export enum OrderCommentConversationType {
  CUSTOMER_ADMIN = 1,
  ADMIN_ADMIN = 2,
}

export enum OrderCommentSenderType {
  CUSTOMER = 1,
  ADMIN = 2,
}

export enum InvoiceStatus {
  CANCELED = 0,
  REFER = 1,
  APPLYING = 2,
  PAID = 3,
}

export enum OrderRequestPaymentType {
  ADMIN = 0,
  CUSTOMER = 1,
}
export enum Continent {
  ASIA = 1,
  EUROPE = 2,
  AMERICA = 3,
  AUSTRALIA = 4,
  AFRICA = 5,
}
export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum ACTIVE {
  TRUE = 1,
  FALSE = 0,
}

export enum LANGUAGE {
  JA = 'ja',
  EN = 'en',
}

export enum CustomerLocationType {
  DOMESTIC = 1,
  FOREIGN = 2,
}

export enum OrderDocumentStatus {
  DRAFT = 1,
  COMPLETED = 2,
}

export enum OptionalServiceCode {
  STORAGE_FEE = 'STORAGE_FEE',
  DOCUMENT = 'DOCUMENT',
  LIMIT_ITEM = 'LIMIT_ITEM',
  TAKE_PICTURE = 'TAKE_PICTURE',
  CUSTOMS_FEE = 'CUSTOMS_FEE',
  INSURANCE = 'INSURANCE',
}

export enum OrderType {
  SHIPPING = 1,
  SHOPPING = 2,
  SHIPPING_VENDOR = 3,
}

export enum AccountStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  UNAUTHENTICATED = 2,
  CANCELED_SUBSCRIPTION = 3,
  TEMPORARILY_LOCKED = 4,
}

export enum VisibilityAccountStatus {
  INACTIVE = 0, //tu choi tao tai khoan
  ACTIVE = 1, //dang hoat dong
  UNAUTHENTICATED = 2, //dang cho phe duyet tao tai khoan
  CANCELED_SUBSCRIPTION = 3, //khach hang da huy subscription
  TEMPORARILY_LOCKED = 4, //tai khoan bi khoa do he thong tu dong khoa khi sub 3 lan that bai hoac admin tu tay vao khoa
  FREE_TRIAL = 5, //tai khoan bussiness dang trong thoi dung thu
  PROCESSING = 6, //tai khoan dang yeu cau nang cap
}

export enum HandleAccountStatusParams {
  INACTIVE = 0, //từ chối tạo tài khoản
  ACTIVE = 1, //chấp nhận tạo tài khoản
  TEMPORARILY_LOCKED = 4, // khóa tài khoản tạm thời
  UNLOCKED = 5, //mở khóa tài khoản khi bị khó
  RECOVER = 6, //khôi phục tài khoản
}

export enum CustomerUpgradeRequestStatus {
  REQUESTED = 1,
  REJECTED = 2,
  ACCEPTED = 3,
}

export enum OrderClassification {
  PAPERS = 1, //giấy tờ, tài liệu
  GOODS = 2, //hàng hóa
}

export enum OrderImageCreatorType {
  CUSTOMER = 1,
  ADMIN = 2,
}

export enum RevenueType {
  SERVICE_FEE = 1,
  MEMBERSHIP_FEE = 2,
}

export enum WarehouseType {
  DEADLINE = 1,
  DELIVER = 2,
}

export enum AuthenticImageType {
  IDENTITY_CARD = 1,
  DRIVER_LICENSE = 2,
}

export enum VendorCode {
  FEDEX = 'FEDEX',
  FEDEX_ECONOMY = 'FEDEX_ECONOMY',
  FEDEX_CONNECT_PLUS = 'FEDEX_CONNECT_PLUS',
  DHL = 'DHL',
  Dmail = 'DMAIL',
}

export const CountryCodeJapan = 'JP';

export enum NotificationLocationType {
  DOMESTIC = 1,
  FOREIGN = 2,
}

export enum InvoiceGroupPostPaidStatus {
  CANCELED = 0,
  REQUESTED = 1,
  PAID = 2,
}

export enum NotificationDisplayType {
  ALL = 0,
  LANDING_PAGE = 1,
  DASHBOARD = 2,
}
