import React, { useState, useEffect } from 'react';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';
import IconMaps from 'components/IconMaps';
import { useTranslation } from 'react-i18next';
import { permissions } from 'common/enum';
import logoWhite from 'assets/images/logoWhite.png';
import { useQuery } from 'react-query';
import * as orderShippingApi from 'api/orderShipping';
import * as orderShoppingApi from 'api/orders';
import * as customerApi from 'api/customer';
import * as warehouseApi from 'api/warehouse';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

export default function SideNav({ sidebarShow, onShowChange }: any) {
  const { t } = useTranslation();
  const [listPermissions, setListPermissions] = useState<any>([]);

  const { data: statisOrderShipping } = useQuery(['statisOrderShipping'], () => {
    return orderShippingApi.getStaticOrderShipping({ isFromAddress: false });
  });
  const { data: statisOrderShippingFromHome } = useQuery(['statisOrderShippingFromHome'], () =>
    orderShippingApi.getStaticOrderShipping({ isFromAddress: true })
  );
  const { data: statisOrderShopping } = useQuery(['statisOrderShopping'], () => orderShoppingApi.getStaticOrder());
  const { data: statisOrderError } = useQuery(['statisOrderError'], () => orderShoppingApi.getStaticOrderError());
  const { data: statisCustomer } = useQuery(['statisCustomer'], () => customerApi.getStatistic(), {
    keepPreviousData: true,
  });
  const { data: statisticWarehouse } = useQuery(['statisticWarehouse'], () => warehouseApi.getStatistic(), {
    keepPreviousData: true,
  });

  useEffect(() => {
    const decoded: any = jwt_decode(Cookies.get('token') || '');
    const listPermissionUser = decoded?.permissions;
    setListPermissions(listPermissionUser.map((x: string) => Number(x)));
  }, []);

  const routes = JSON.parse(
    JSON.stringify([
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.dashboard'),
        to: '/',
        icon: 'DashBoard',
        visible: !!listPermissions?.includes(permissions.DASHBOARD),
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.list_order'),
        icon: 'ListOrder',
        _children: [
          {
            _tag: 'CSidebarNavDropdown',
            name: t('nav.shipping_nav'),
            icon: 'Shipping',
            visible: !!listPermissions?.includes(permissions.SHIPPING_MANAGEMENT),
            _children: [
              {
                _tag: 'CSidebarNavItem',
                name: t('nav.shipping'),
                badge: {
                  color: 'warning',
                  text: Number(statisOrderShipping?.data?.request || 0),
                },
                to: '/shipping',
                visible: !!listPermissions?.includes(permissions.SHIPPING_MANAGEMENT),
              },
              {
                _tag: 'CSidebarNavItem',
                name: t('nav.shipping_from_address'),
                badge: {
                  color: 'warning',
                  text: Number(statisOrderShippingFromHome?.data?.request || 0),
                },
                to: '/shipping-from-address',
                visible: !!listPermissions?.includes(permissions.SHIPPING_MANAGEMENT),
              },
            ],
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.shopping_agency'),
            to: '/shopping',
            icon: 'Shopping',
            visible: !!listPermissions?.includes(permissions.SHOPPING_MANAGEMENT),
            badge: {
              color: 'warning',
              text: statisOrderShopping?.data?.request,
            },
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.order_error'),
            to: '/order-error',
            icon: 'OrderError',
            visible: listPermissions.some((permission: permissions) =>
              [permissions.SHOPPING_MANAGEMENT, permissions.SHIPPING_MANAGEMENT].includes(permission)
            ),
            badge: {
              color: 'warning',
              text: statisOrderError?.data || null,
            },
          },
        ],
        visible: listPermissions.some((permission: permissions) =>
          [permissions.SHOPPING_MANAGEMENT, permissions.SHIPPING_MANAGEMENT].includes(permission)
        ),
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: t('nav.report_list'),
      //   to: '/report-list',
      //   icon: 'Operator',
      //   visible: !!listPermissions?.includes(permissions.REPORT_ORDER_CUSTOMER),
      // },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.warehouse'),
        to: '/warehouse',
        icon: 'WareHouse',
        visible: !!listPermissions?.includes(permissions.WAREHOUSE),
        badge: {
          color: 'danger',
          text: !!statisticWarehouse?.data?.totalOrderDeadline
            ? statisticWarehouse?.data?.totalOrderDeadline
            : undefined,
        },
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.customer'),
        to: '/customer',
        icon: 'Customer',
        visible: !!listPermissions?.includes(permissions.CUSTOMER_MANAGEMENT),
        badge: {
          color: 'success',
          text: statisCustomer?.data?.totalCustomer,
        },
      },
      {
        _tag: 'CSidebarNavItem',
        name: t('nav.revenue'),
        to: '/revenue',
        icon: 'Revenue',
        visible: !!listPermissions?.includes(permissions.REVENUE),
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.bill'),
        icon: 'Payment',
        visible: !!listPermissions?.includes(permissions.INVOICE_ORDER_CUSTOMER),
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.bill_by_order'),
            to: '/invoice',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.LANGUAGE_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.bill_recurring'),
            to: '/invoice-recurring',
            visible: !!listPermissions?.includes(permissions.PERMISSION_MANAGEMENT),
          },
        ],
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.system'),
        icon: 'Setting',
        visible: listPermissions.some((permission: permissions) =>
          [permissions.LANGUAGE_MANAGEMENT, permissions.PERMISSION_MANAGEMENT, permissions.USER_MANAGEMENT].includes(
            permission
          )
        ),
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.language'),
            to: '/languages',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.LANGUAGE_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.role'),
            to: '/roles',
            visible: !!listPermissions?.includes(permissions.PERMISSION_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.staff'),
            to: '/staffs',
            visible: !!listPermissions?.includes(permissions.USER_MANAGEMENT),
          },
        ],
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: t('nav.config'),
        icon: 'SettingFilled',
        visible: listPermissions.some((permission: permissions) =>
          [
            permissions.CONFIG_MANAGEMENT,
            permissions.OPTION_SERVICE_MANAGEMENT,
            permissions.VENDOR_MANAGEMENT,
            permissions.CATEGORY_MANAGEMENT,
            permissions.DOCUMENT_MANAGEMENT,
            permissions.TEMPLATE_MAIL_MANAGEMENT,
            permissions.COUNTRY_MANAGEMENT,
            permissions.REASON_REPORT_MANAGEMENT,
          ].includes(permission)
        ),
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.parameters'),
            to: '/configs',
            visible: !!listPermissions?.includes(permissions.CONFIG_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.warehouse_address'),
            to: '/warehouse-address',
            visible: !!listPermissions?.includes(permissions.CONFIG_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.config_fee_general'),
            to: '/config-fee-general',
            visible: !!listPermissions?.includes(permissions.CONFIG_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.config_commission_fee_lp'),
            to: '/commission-fee',
            visible: !!listPermissions?.includes(permissions.CONFIG_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.notification'),
            to: '/notification',
            visible: !!listPermissions?.includes(permissions.CONFIG_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.optional_service'),
            to: '/optional-service',
            visible: !!listPermissions?.includes(permissions.OPTION_SERVICE_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.optional_service_lp'),
            to: '/optional-service-lp',
            visible: !!listPermissions?.includes(permissions.OPTION_SERVICE_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.importer'),
            to: '/importer',
            visible: !!listPermissions?.includes(permissions.OPTION_SERVICE_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.delivery_company'),
            to: '/delivery',
            icon: '',
            visible: !!listPermissions?.includes(permissions.VENDOR_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.category_manager'),
            to: '/category',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.CATEGORY_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.paper_manager'),
            to: '/paper',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.DOCUMENT_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.mail_template'),
            to: '/mail-template',
            visible: !!listPermissions?.includes(permissions.TEMPLATE_MAIL_MANAGEMENT),
          },
          {
            _tag: 'CSidebarNavItem',
            name: t('nav.national'),
            to: '/national',
            icon: 'FileSyncOutlined',
            visible: !!listPermissions?.includes(permissions.COUNTRY_MANAGEMENT),
          },
          // {
          //   _tag: 'CSidebarNavItem',
          //   name: t('nav.reason_report'),
          //   to: '/reason-report',
          //   icon: 'FileSyncOutlined',
          //   visible: listPermissions?.includes(permissions.REASON_REPORT_MANAGEMENT),
          // },
        ],
      },
    ]),
    (key, value: any) => {
      if (key === 'icon') {
        return IconMaps[value];
      }
      return value;
    }
  );

  return (
    <CSidebar
      minimize={sidebarShow}
      // onShowChange={(val: any) => onShowChange(val)}
      onMinimizeChange={(value: any) => console.log(value)}
      size={'lg'}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <div className="logoHead">
          <img src={logoWhite} alt="" className="img-fluid" />
        </div>
      </CSidebarBrand>
      <CSidebarNav className="hNav">
        <CCreateElement
          items={routes.filter((item: any) => !!item.visible)}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
}
