import { sendGet, sendPut, sendPost } from './axios';

// eslint-disable-next-line import/prefer-default-export
export const getListRoles = (params: any) => sendGet('/cms/permission/role', params);

export const addRole = (payload: any) => sendPost('/cms/permission/role', payload);

export const updateRole = (roleId: number, payload: any) => sendPut(`/cms/permission/role/${roleId}`, payload);

export const hiddenRole = (roleId: number) => sendPut(`/cms/permission/hidden-role/${roleId}`);

export const getListPermissionByRole = (roleId: number) => sendGet(`/cms/permission/rolePermission/${roleId}`);

export const updatePermissionByRole = (roleId: number, payload: any) =>
  sendPut(`/cms/permission/rolePermission/${roleId}`, payload);

