import configs from 'config';
import CustomNotification from 'components/CustomNotification';
import {
  ConfigKeys,
  CustomerAccountType,
  InvoiceStatus,
  OptionalServiceType,
  OrderStatus,
  CustomerLocationType,
  OrderPaymentStatus,
  OrderImageCreatorType,
  OrderType,
  InvoiceType,
  CommonStatus,
  CountryCodeJapan,
  VendorCode,
} from 'common/enum';
import moment from 'moment';
import i18next from 'i18next';
import _ from 'lodash';

export const customLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const handleErrorMessage = (error: any) => {
  CustomNotification({
    type: 'error',
    message: i18next.t('common.error'),
    description: getErrorMessage(error),
  });
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const handleErrorMessageClient = (errorMsg: any) => {
  CustomNotification({
    type: 'error',
    message: i18next.t('common.error'),
    description: errorMsg,
  });
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.errorMessage || 'Something went wrong!';
};

export const assignDomain = (url: string) => {
  if (url?.startsWith('http')) return url;
  return `${configs.AWS_DOMAIN}/${url}`;
};

export const getBase64 = (img: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const getDataFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsText(file);
  });

export const convertOptionalServiceType = (optionType: number, t: any) => {
  switch (optionType) {
    case OptionalServiceType.SHIPPING:
      return t('option.shipping');
    case OptionalServiceType.SHOPPING:
      return t('option.shopping');
    case OptionalServiceType.BOTH:
      return t('option.both');
    default:
      return '--';
  }
};

export const convertShoppingStep = (trackingType: number, t: any) => {
  switch (trackingType) {
    case OrderStatus.REQUESTED:
      return t('option.requested');
    case OrderStatus.PAYMENT_PENDING:
      return t('option.payment_pending');
    case OrderStatus.PURCHASING:
      return t('option.purchasing');
    case OrderStatus.IMPORTED:
      return t('option.imported');
    case OrderStatus.CREATE_TRANSPORT_ORDER:
      return t('option.created_transport_order');
    case OrderStatus.PAYMENT_CONFIRMED:
      return t('option.imported');
    case OrderStatus.DELIVERING:
      return t('option.delivering');
    case OrderStatus.COMPLETED:
      return t('option.order_done');
    case OrderStatus.DELIVERY_FAILED:
      return t('option.order_done');
    case OrderStatus.CANCELED:
      return t('option.canceled');
    default:
      return '';
  }
};

export const convertShippingStep = (trackingType: number, t: any) => {
  switch (trackingType) {
    case OrderStatus.REQUESTED:
      return t('option.requested');
    case OrderStatus.IMPORTED:
      return t('option.imported');
    case OrderStatus.CREATE_TRANSPORT_ORDER:
      return t('option.created_transport_order');
    case OrderStatus.PAYMENT_CONFIRMED:
      return t('option.imported');
    case OrderStatus.DELIVERING:
      return t('option.delivering');
    case OrderStatus.COMPLETED:
      return t('option.order_done');
    case OrderStatus.DELIVERY_FAILED:
      return t('option.order_done');
    case OrderStatus.CANCELED:
      return t('option.canceled');
    default:
      return '';
  }
};

export const convertShippingFromHomeStep = (trackingType: number, t: any) => {
  switch (trackingType) {
    case OrderStatus.REQUESTED:
      return t('option.requested');
    case OrderStatus.CREATE_TRANSPORT_ORDER:
      return t('option.order_processing');
    case OrderStatus.PICKED_UP:
      return t('option.picked_up');
    case OrderStatus.COMPLETED:
      return t('option.order_done');
    case OrderStatus.DELIVERY_FAILED:
      return t('option.order_done');
    case OrderStatus.CANCELED:
      return t('option.canceled');
    default:
      return '';
  }
};

export const convertDate = (date: string) => {
  return date ? moment(date).format('YYYY-MM-DD') : '';
};

export const convertAccountType = (accountType: CustomerAccountType, t: any) => {
  switch (accountType) {
    case CustomerAccountType.PERSONAL:
      return t('option.personal');
    case CustomerAccountType.BUSINESS:
      return t('option.business');
    default:
      return '--';
  }
};

export const convertLocationType = (accountType: CustomerLocationType, t: any) => {
  switch (accountType) {
    case CustomerLocationType.DOMESTIC:
      return t('option.domestic');
    case CustomerLocationType.FOREIGN:
      return t('option.foreign');
    default:
      return '--';
  }
};

export const getConfigByKeys = (dataConfig: Array<any>, key: string) => {
  return dataConfig?.find((item) => item.key === key);
};

export const getProcessFeeKey = (accountType: CustomerAccountType) => {
  return accountType === CustomerAccountType.BUSINESS
    ? ConfigKeys.COMMON_SERVICE_FEE_FOREIGN_BUSINESS
    : ConfigKeys.COMMON_SERVICE_FEE_FOREIGN_PERSONAL;
};

export const getDeliveryFeeKey = (accountType: CustomerAccountType) => {
  return accountType === CustomerAccountType.BUSINESS
    ? ConfigKeys.SHIPPING_FEE_FOREIGN_BUSINESS
    : ConfigKeys.SHIPPING_FEE_FOREIGN_PERSONAL;
};

export const convertInvoiceStatus = (invoiceStatus: InvoiceStatus, t: any) => {
  switch (invoiceStatus) {
    case InvoiceStatus.CANCELED:
      return t('option.canceled');
    case InvoiceStatus.REFER:
      return t('option.payment_pending');
    case InvoiceStatus.APPLYING:
      return t('option.payment_pending');
    case InvoiceStatus.PAID:
      return t('option.paid');
    default:
      return t('option.payment_pending');
  }
};

export const convertInvoiceStatusShipping = (invoiceStatus: InvoiceStatus, t: any) => {
  switch (invoiceStatus) {
    case InvoiceStatus.CANCELED:
      return t('option.canceled');
    case InvoiceStatus.REFER:
      return t('option.refer');
    case InvoiceStatus.APPLYING:
      return t('option.payment_pending');
    case InvoiceStatus.PAID:
      return t('option.paid');
    default:
      return t('option.payment_pending');
  }
};

export function renderPriceOptionService(dataItem: any, accountType: number, locationType: number) {
  let price = 0;
  if (locationType === CustomerLocationType.DOMESTIC) {
    price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalDomesticPrice : dataItem.businessDomesticPrice;
  }
  if (locationType === CustomerLocationType.FOREIGN) {
    price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalForeignPrice : dataItem.businessForeignPrice;
  }
  return price;
}

export function convertPrice(dataItem: any, accountType: number, locationType: number, currentCyRate: any) {
  let price = 0;
  if (locationType === CustomerLocationType.DOMESTIC) {
    price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalDomesticPrice : dataItem.businessDomesticPrice;
    const money = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }).format(price);
    return money;
  }
  if (locationType === CustomerLocationType.FOREIGN) {
    price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalForeignPrice : dataItem.businessForeignPrice;
    price = Number(price);
    currentCyRate = Number(currentCyRate);
    const result = price / currentCyRate;
    const money = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(result);
    return money;
  }
}

export function convertPriceJP(price: number, currentCyRate: any) {
  price = price || 0;
  const moneyJP = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(price);
  const convertPriceJPToUSD = Number(price) / Number(currentCyRate);
  const moneyUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(convertPriceJPToUSD);
  const renderPrice = `${moneyJP} (${moneyUSD})`;
  return renderPrice;
}

export function convertPriceJPWithoutCurrency(price: number, currentCyRate: any) {
  if (!price)
    return {
      moneyJP: '--',
      moneyUSD: '--',
    };
  price = price || 0;
  const moneyJP = new Intl.NumberFormat('ja-JP').format(price);
  const convertPriceJPToUSD = Number(price) / Number(currentCyRate);
  const moneyUSD = new Intl.NumberFormat('en-US').format(convertPriceJPToUSD);
  return {
    moneyJP,
    moneyUSD,
  };
}

export function convertPriceWithoutRate(price: number | string) {
  return `¥ ${Number(price).toLocaleString()}`;
}

export function convertWeight(weight: number) {
  return !!weight ? `${formatNumber(weight)}g` : `--`;
}

export function convertDimension(width: number, length: number, height: number) {
  return `${formatNumber(length)}x${formatNumber(height)}x${formatNumber(width)}cm`;
}

export function getPriceForCustomer(dataItem: any, accountType: number, locationType: number) {
  if (locationType === CustomerLocationType.DOMESTIC) {
    const price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalDomesticPrice : dataItem.businessDomesticPrice;
    return price;
  }
  if (locationType === CustomerLocationType.FOREIGN) {
    const price =
      accountType === CustomerAccountType.PERSONAL ? dataItem.personalForeignPrice : dataItem.businessForeignPrice;
    return price;
  }
}

export const getPaymentStatus = (status: OrderPaymentStatus, t: any) => {
  switch (status) {
    case OrderPaymentStatus.PENDING:
      return t('option.payment_pending');
    case OrderPaymentStatus.CONFIRMED:
      return t('option.payment_confirmed');
    case OrderPaymentStatus.ADDITIONAL:
      return t('option.payment_additional');
    default:
      return '--';
  }
};

export const getMinimumVendorFee = (vendorQuotation: Array<any>) => {
  return vendorQuotation?.sort((a: { fee: number }, b: { fee: number }) => a.fee - b.fee)[0]?.fee || 0;
};

export const convertVendorName = (vendorQuotation: Array<any>, vendorCode: string) => {
  return vendorQuotation?.find((vendor: { id: number; code: string }) => vendor.code === vendorCode)?.name;
};

export const getDefaultVendor = (vendorQuotation: Array<any>, vendorCode: string) => {
  const vendorAvailable = vendorQuotation?.filter((vendor) => vendor.fee > 0);
  return (
    vendorAvailable?.find((vendor: { id: number; code: string }) => vendor.code === vendorCode) ||
    vendorAvailable?.sort((a: { fee: number }, b: { fee: number }) => a.fee - b.fee)[0]
  );
};

export const formatNumber = (value: number) => {
  return value ? new Intl.NumberFormat().format(value) : 0;
};

export const convertNumberFormatToNumber = (value: string | number) => {
  return typeof value === 'number' ? value : Number(value?.replace(/,/g, ''));
};

export function isJanpanBusiness(accountType: CustomerAccountType, locationType: CustomerLocationType) {
  return accountType === CustomerAccountType.BUSINESS && locationType === CustomerLocationType.DOMESTIC ? true : false;
}

export const getOrderStatus = (type: number, t: any) => {
  const orderStatus = '--';
  switch (type) {
    case OrderStatus.REQUESTED:
      return t('option.requested');
    case OrderStatus.PAYMENT_PENDING:
      return t('option.payment_pending');
    case OrderStatus.PURCHASING:
      return t('option.purchasing');
    case OrderStatus.IMPORTED:
      return t('option.imported');
    case OrderStatus.PAYMENT_CONFIRMED:
      return t('option.confirmed');
    case OrderStatus.CREATE_TRANSPORT_ORDER:
      return t('option.created_transport_order');
    case OrderStatus.PICKED_UP:
      return t('option.picked_up');
    case OrderStatus.DELIVERING:
      return t('option.delivering');
    case OrderStatus.ERROR:
      return t('option.error');
    case OrderStatus.COMPLETED:
      return t('option.completed');
    case OrderStatus.DELIVERY_FAILED:
      return t('option.delivery_failed');
    case OrderStatus.CANCELED:
      return t('option.canceled');
  }
  return orderStatus;
};

export function convertAddress(address: any) {
  if (!address) return;
  const countryName = address?.country?.name || address?.countryName;
  const { zipCode, stateCode, province, address1, address2, warehouseBlockCode, country } = address;
  let result;
  if (country?.code === CountryCodeJapan) {
    result =
      `〒${zipCode}${stateCode ? ` ${stateCode}` : ''}, ${province}, ${address1}` +
      (!!address2 ? `, ${address2}` : '') +
      `${countryName ? `, ${countryName}` : ``} ${warehouseBlockCode ? `- ${warehouseBlockCode}` : ``}`;
  }
  if (country?.code !== CountryCodeJapan) {
    result = `${!!address2 ? `${address2}, ` : ''} ${address1}, ${province}, ${
      stateCode ? `${stateCode} ` : ''
    }${zipCode}${countryName ? `, ${countryName}` : ``} ${warehouseBlockCode ? ` - ${warehouseBlockCode}` : ``}`;
  }
  return result;
}

export const getAccountType = (value: any, t: any) => {
  if (value?.accountType === CustomerAccountType.BUSINESS) {
    return t('option.business');
  }
  return t('option.personal');
};

export const getCountryName = (listCountry: Array<any>, countryId: number) => {
  return listCountry.find((country: any) => country.id === countryId)?.name || '';
};

export const getImageAuthor = (creatorType: OrderImageCreatorType, customerFullName?: string) => {
  return creatorType === OrderImageCreatorType.CUSTOMER ? customerFullName : `Admin`;
};

export const checkPathIsMultipleBarcodeError = (pathName: string, orderType: OrderType, status: OrderStatus) => {
  if (
    pathName === '/shipping/import-warehouse-multiple' &&
    orderType !== OrderType.SHIPPING &&
    status !== OrderStatus.REQUESTED
  )
    return true;
  if (
    pathName === '/shipping/stock-delivery-multiple' &&
    orderType !== OrderType.SHIPPING &&
    status !== OrderStatus.CREATE_TRANSPORT_ORDER
  )
    return true;
  if (
    pathName === '/shopping/import-warehouse-multiple' &&
    orderType !== OrderType.SHOPPING &&
    status !== OrderStatus.PURCHASING
  )
    return true;
  if (
    pathName === '/shipping/stock-delivery-multiple' &&
    orderType !== OrderType.SHOPPING &&
    status !== OrderStatus.CREATE_TRANSPORT_ORDER
  )
    return true;
  return false;
};

export const checkOrderRequest = (orderType: OrderType, status: OrderStatus) => {
  if (orderType === OrderType.SHIPPING && status === OrderStatus.REQUESTED) return true;
  if (orderType === OrderType.SHIPPING && status === OrderStatus.CREATE_TRANSPORT_ORDER) return true;
  if (orderType === OrderType.SHOPPING && status === OrderStatus.PURCHASING) return true;
  if (orderType === OrderType.SHOPPING && status === OrderStatus.CREATE_TRANSPORT_ORDER) return true;
  return false;
};

export const getOrderDeliveryFee = (orderDetail: any) => {
  return (
    orderDetail?.deliveryFee ||
    orderDetail?.invoices?.find((invoice: any) => invoice.status !== InvoiceStatus.CANCELED)?.deliveryFee ||
    0
  );
};

export const getProcessingFeeInvoice = (
  invoices: Array<{ invoiceType: InvoiceType; processingFee: number; status: InvoiceStatus }>
) => {
  const invoiceService = invoices?.filter(
    (invoice) => invoice.invoiceType !== InvoiceType.ADDITIONAL && invoice.status !== InvoiceStatus.CANCELED
  );
  return invoiceService[invoiceService?.length - 1]?.processingFee || 0;
};

export const convertOrderType = (orderType: OrderType, t: any) => {
  switch (orderType) {
    case OrderType.SHIPPING:
      return t('option.shipping');
    case OrderType.SHIPPING_VENDOR:
      return t('option.shipping_address');
    case OrderType.SHOPPING:
      return t('option.shopping');
    default:
      return '--';
  }
};

export function converPercent(amount: number | string, totalAmount: number | string) {
  return Math.floor((Number(amount) / Number(totalAmount)) * 1000) / 10;
}

export function checkLastInvoiceIsTransfer(invoices: Array<{ isTransfer: CommonStatus }>) {
  return invoices[invoices?.length - 1]?.isTransfer === CommonStatus.ACTIVE;
}

export function checkLastInvoiceIsApply(invoices: Array<{ status: InvoiceStatus }>) {
  return invoices[invoices?.length - 1]?.status === InvoiceStatus.APPLYING;
}

export const getTotalInvoiceAdditional = (invoices: Array<{ status: InvoiceStatus }>) => {
  return _.sumBy(
    invoices.filter((invoice: any) => invoice.invoiceType === InvoiceType.ADDITIONAL),
    (invoice: any) => invoice.totalAmount
  );
};

export const checkLastInvoiceIsPaid = (invoices: Array<{ invoiceType: InvoiceType; status: InvoiceStatus }>) => {
  const invoiceNormal = invoices?.filter(
    (invoice: { invoiceType: InvoiceType }) => invoice.invoiceType === InvoiceType.DELIVERY
  );
  const lastInvoice = invoiceNormal[invoiceNormal.length - 1];
  return lastInvoice?.status === InvoiceStatus.PAID;
};

export const checkBusinessDomesticConfirmPayment = (
  customer: {
    accountType: CustomerAccountType;
    locationType: CustomerLocationType;
  },
  status: OrderStatus
) => {
  const orderIsConfirmPayment = status === OrderStatus.PAYMENT_CONFIRMED;
  const customerIsBusinnessDomestic =
    customer.locationType === CustomerLocationType.DOMESTIC && customer.accountType === CustomerAccountType.BUSINESS;
  return orderIsConfirmPayment && customerIsBusinnessDomestic;
};

export const getCurrentTabFilter = (status: any) => {
  const arrayStatus: any = Array.isArray(status) ? status : [Number(status)];
  return arrayStatus.join(',');
};

export const formulaCalculation = (dataMass: any, code: any) => {
  let { length, width, height, weight } = dataMass;
  weight = Number(weight);
  length = Number(length);
  width = Number(width);
  height = Number(height);
  if (code === VendorCode.FEDEX || code === VendorCode.FEDEX_ECONOMY || code === VendorCode.FEDEX_CONNECT_PLUS) {
    const maxWeight = Math.floor(68038.9);
    const maxLenght = 274; //cm
    const maxLenghtWidthHeight = 330; //cm
    const totalFormula = length + 2 * width + height * 2;
    if (weight > maxWeight || totalFormula > maxLenghtWidthHeight || length > maxLenght) {
      return false;
    }
    return true;
  }
  if (code === VendorCode.DHL) {
    const maxWeight = 70000; //g
    const maxLenght = 120; //cm
    const maxWidth = 80; //cm
    const maxHeight = 80; //cm
    if (weight > maxWeight || length > maxLenght || width > maxWidth || height > maxHeight) {
      return false;
    }
    return true;
  }
  if (code === VendorCode.Dmail) {
    const maxWeight = 2000; //g
    const maxLenght = 60; //cm
    const maxLenghtWidthHeight = 90; //cm
    const totalLengthWidthHeight = length + width + height;
    if (totalLengthWidthHeight > maxLenghtWidthHeight || length > maxLenght || weight > maxWeight) {
      return false;
    }
    return true;
  }
};
