import { sendPut, sendDelete, sendPost } from './axios';

export const getListLanguageByEnvironment = (params: any) =>
  sendPost('/cms/language/get-file-language', params);

export const getListLanguages = () => sendPost('/cms/language/list');

export const updateLanguageKey = (payload: any) =>
  sendPut('/cms/language/update-language-key', payload);

export const addLanguageKey = (payload: any) => sendPut('/cms/language/add-language-key', payload);

export const deleteLanguageKey = (languageKey: any) => sendDelete(`/v1/cms/language/deleteKey/${languageKey}`);

export const uploadFile = (payload: any) => sendPost('/cms/language/upload-file-language', payload);

export const getListEnvironments = () => sendPost('/cms/language/list-environments');



export const getListLanguageKey = (params: any) => sendPost('/cms/language/list-language-key', params);
