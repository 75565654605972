// @ts-ignore
import ScannerDetector from 'js-scanner-detection';

new ScannerDetector({
  onComplete: (code: any) => {
    code = code.match(/CL\d+/g)?.[0];
    if (code) {
      const event = new CustomEvent('barcode', { detail: { code } });
      window.dispatchEvent(event);
    }
  },
});
